.root {
  z-index: 2000;

  &[x-placement*="bottom"] .arrow {
    width: 0;
    height: 0;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: 1em solid #b2b2b2;
    margin-top: -0.9em;

    &:before {
      border-width: 0em 0.8em 0.8em 0.4rem;
      border-color: transparent transparent white transparent;
      transform: translate(-0.85em, 0.2em);
    }
  }

  &[x-placement*="top"] .arrow {
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-top: 1em solid #b2b2b2;
    margin-bottom: -0.9em;

    &:before {
      border-width: 0.8em 0.8em 0em 0.4rem;
      border-color: white transparent transparent transparent;
      transform: translate(-0.8em, -1em);
    }
  }

  &[x-placement*="right"] .arrow {
    left: 0;
    width: 0;
    height: 0;
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-right: 1em solid #b2b2b2;
    margin-left: -0.9em;

    &:before {
      border-width: 0.8em 0.8em 0.8em 0;
      border-color: transparent white transparent transparent;
      transform: translate(0.25em, -0.8em);
    }
  }

  &[x-placement*="left"] .arrow {
    right: 0;
    width: 0;
    height: 0;
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-left: 1em solid #b2b2b2;
    margin-right: -0.9em;

    &:before {
      border-width: 0.8em 0.8em 0.8em 0.8em;
      border-color: transparent transparent transparent white;
      transform: translate(-1.1em, -0.95em);
    }
  }

  .arrow {
    position: absolute;
    font-size: 7px;

    &:before {
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      content: "";
    }
  }

  .pop {
    font-size: 14px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16),
      0px 0px 1px rgba(0, 0, 0, 0.14);
    border: 1px solid #b2b2b2;
    // border-left-width: 5px;
    border-radius: 4px;
    background-color: white;
    padding: 16px;
  }
}
