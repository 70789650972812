* {
  font-family: "CiscoSans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  // white-space: pre !important;
  &:focus {
    box-shadow: inherit !important;
  }
  // @media (max-width: 1279.95px) {
  //   font-size: 0.6rem;
  // }
  font-size: 0.75rem;
}

.rte-area {
  line-height: 1.5;
  * {
    word-break: break-word;
    //max-width: 18vw;
    font-size: unset;
  }
}

//@media screen and (max-width: 1280px) {
//  :root {
//    zoom: calc(2 / 3);
//  }
//}

a {
  &:focus {
    box-shadow: none;
  }
  &:hover {
    text-decoration: underline;
  }
}

.Toastify {
  &__close-button {
    color: #383c40 !important;
  }
  &__toast {
    &-container {
      margin-top: 80px;
    }

    &-body {
      font-size: 0.9rem;
      color: #383c40;
    }

    &--error {
      background-color: white !important;
      border-left: 5px solid #ff9987;
      .Toastify__progress-bar {
        background-color: #ff9987;
      }
    }

    &--info {
      background-color: white !important;
      border-left: 5px solid #1dc4f2;

      .Toastify__progress-bar {
        background-color: #1dc4f2;
      }
    }

    &--success {
      background-color: white !important;
      border-left: 5px solid #44cf50;
      .Toastify__progress-bar {
        background-color: #44cf50;
      }
    }
  }
}

.new-account {
  background-color: #e8fae9 !important;
}

.inactive-account {
  background-color: #fff2ed !important;
}

.overdue-activity {
  background-color: #fff5f2 !important;
}

.no-action {
  background: #f8f8f8 !important;
}

.contact-selected {
  background-color: #eefdff !important;
}

.checkbox-header {
  padding-left: 68px !important;
}
.ag-theme-material {
  border: 1px solid #dedede;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}
.detail-grid {
  .ag-header {
    background-color: #fff !important;
    height: 46px;
    &-cell {
      background-color: #f3fdfe;
      border-bottom: 1px solid #d2d5d6;
      height: inherit;
      &-text {
        color: #9b9b9b;
        font-size: 10px;
      }
    }
  }
  .ag-row {
    background-color: #f3fdfe;
  }
}
.email-contact-detail-grid {
  .ag-row {
    background-color: #f8f9fb !important;
  }
  .ag-header {
    background-color: #f8f9fb !important;
    &-viewport {
      border-bottom: 1px solid #d2d5d6;
    }
    &-cell {
      background-color: #f8f9fb !important;

      height: inherit;
      &-text {
        color: #333333;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
  .ag-center-cols-clipper {
    background-color: #f8f9fb;
  }
}

.email-contact-grid {
  .ag-row-group-expanded {
    background-color: #eefdff;
  }
  // .ag-row-group-indent-1 {
  //   padding-left: 0;
  // }
}

.ag-side-button-button.active {
  font-weight: 700;
  color: #00a0d1 !important;
}

.ag-filter-icon {
  color: #00a0d1 !important;
}

.ag-filter-apply-panel {
  padding: 12px !important;
  justify-content: space-around !important;
  &-button {
    cursor: pointer !important;
    box-shadow: none !important;
    margin-left: 12px !important;
  }
}

.ag-cell-wrap-text {
  white-space: normal;
  word-break: break-word;
}

.ag-header {
  border-bottom: none !important;
  background-color: #ededed !important;
  &-cell {
    background-color: #ededed;

    & .ag-react-container {
      width: 100%;
    }
    &-text {
      font-weight: 700;
      color: #333333;
      font-size: 12px;
    }
  }
}

.ag-cell-wrapper {
  height: auto;
}

.ag-cell-value {
  font-weight: 400;
  color: #535759;
}

//.ag-icon-filter {
//  color: deepskyblue;
//}

.ag-row {
  height: 44px;
  .hide_cell_indent {
    .ag-row-group-indent-1 {
      padding-left: 0;
    }
  }
  .checkbox_cell {
    .ag-selection-checkbox {
      margin-right: 4px;
      // padding: 0 24px;
    }
    .ag-cell-value {
      overflow: visible;
    }
  }
  &-hover .hover-display {
    display: inline-block !important;
  }
}

.column_disabled {
  cursor: not-allowed !important;
  color: lightgrey;
  //order: 1;

  .ag-column-select-checkbox {
    pointer-events: none;
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: lightgrey !important;
  }

  .ag-column-select-column-label {
    pointer-events: none;
  }

  .ag-column-select-column-drag-handle {
    cursor: not-allowed !important;
  }
}

.children_column_disabled {
  cursor: not-allowed !important;
  color: lightgrey;
  //order: 1;

  .ag-column-select-checkbox {
    pointer-events: none;
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: lightgrey !important;
  }

  .ag-column-select-column-label {
    pointer-events: none;
  }

  .ag-column-select-column-drag-handle {
    cursor: not-allowed !important;
  }
}

//for ag grid tooltip need clickable
.tooltip {
  &_container {
    position: relative;
    display: inline-block;
    & i {
      color: #898989;
    }
    &:hover .tooltip_text {
      display: inline-block;
      min-width: 100px;
      padding: 4px 6px;
    }
  }
  &_text {
    position: absolute;
    display: none;
    background-color: #fff;
    border: 1px solid #00a0d1;
    color: black;
    padding: 0 8px;
    z-index: 2000;
    border-radius: 6px;
    bottom: -65%;
    left: 0;
    &_link {
      text-decoration: underline;
    }
    &:hover {
      display: inline-block;
    }
  }
}

.children_column_disabled {
  cursor: not-allowed !important;
  color: lightgrey;
  // order: 1;

  .ag-column-select-checkbox {
    pointer-events: none;
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: lightgrey !important;
  }

  .ag-column-select-column-label {
    pointer-events: none;
  }

  .ag-column-select-column-drag-handle {
    cursor: not-allowed !important;
  }
}

.cell_new_indicator::before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAYAAADjVADoAAAElUlEQVR4Xu3az1LTQBgA8G83reNNoHoWnsBI8Sw8gfoE4s2xHOoTWJ5AmKHozfIGvoH1rNH0CcSzVnp0hM3nfDFbQsifTbJpt6m96Awhzf76/dstbNxpI/x/nbD/EHDS6ju7yw7hI1BCmAbxHQDuzihTpwimQYx4U2yLC/6eIXtYMcYVBKMgkMGT20fO+7Pn9rpnWd8qhLiGYBQEAJy2+s4GPdS40x4AwNMKMGIRTIMAYGy/dfS5F0SFCwC3NGIkIpgHATDhQtxffeuejve2eoD4ShNEKsI8IUbIoAeA3WhhZADDtb6zc9a1V7xz61RDVGQizAviO28Ke/XAndAD/HyxucsYOwgvWBZODVGhhDAvCOBCbFD4y7D3P/2LRjeUChPeFBuENe606bois4UywtwgZPj/2Nvapoe4c/R5SP9SkRQNPvDTBeGwdex0g4h5l7NW5EKYGwS9scfYDgGMO22XAZwxIZ7JKCEgjjjwGNuV1wDAPUWM3AizgqCxeSWm6PlzQ7DoD/4iEXv8hnco6wf9jCCuXJOuUQhhFhD+2Ay/YQUt6wABHl1ZRzA3RAaoCQJ0b/edk/C1P/c2hxmjd2GE6iEQe63jL/tyQTLkQ8XPnxvgJkyirZLqCEP+cvX4kxu0UooaOyEgSiFUCTECwAEAe02jMzJ4SfsIuYigLXaDdPEXMe5sdoPro2ulcZsAKkOoCsJPB8rzX532BwTwOwN9woKx/XCHkOkSLpw5iiLdtnQkSHXd5xFThHGnTS3PP/SIvAZciP1wh7AQu2t953GOoqgVQXdEqCBcmkQ6BNWBYIBS2XlqiwTdEZGFMAKA9ZgWOgEGvdaRcygfSOE8QjuCrojIRPBb6J/muse8rwlV3y+oVkMMvXOr0u6QNIaUrRFKCEHIJ9WM8LPRRoyGr7hXJZGgKzUGrb7zjG4WsyfIQkr6cGaOoCs14jBUEGhypNkgaw9RaSSUiYgTjvzAYx7tGOVR2iXGXvsx5XpGOviLm1dhjAu5vDViek5w9uKBnYRBb5QyR/xD+HcCNZfCqAOC7uHyptihTzwNI+EcwUiEMjWiCIaxCGUg8kbGtonpEE6RvDUiml5KkUG/ZFpNiC4kDWIECEPkaGcciGRimI6QlhrTOSC8BxANy2bAbARvmyGjvYM8XU7FmMV5Qp7pLE/XmC5MhrU8RwzfhD7pi4uGzdHzD01a/S/0/QTEdJOk55zJsKSClJYaUwzZCulwBYG5COhayEd0jJb0JgpnC8YgqHSNaxgxCycMFxi4XHB39c2nj4tQE/IUS3mtCkb0vnPbRaqkQZ4akdgmC37zJO9nVDoUnSOKREb4vYxFUKkRuiLDaIQiEFdGa8U0MR6hKMQUg/7jnVt0DknDVdxrIRDKQPgYwcor/QaqaBfI+3tlN13GT4yqIFVALEw6FG2fKrgLiVC2RkRhFhZBJ8RCI+iCWHgEHRC1QCgLURuEMhC1QigKUTuEIhC1RMgLUVuEPBC1RlCFqD2CCsRSIGRBLA1CGsRSISRBLB1CHMRSIkQhlhYhDLHUCBKC/jQw7q/oVY7manPNX78Qqpup3VCRAAAAAElFTkSuQmCC");
  content: " ";
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  background-size: 30px 30px;
}

.cell_lcm_indicator::before {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzEuNDA0IiBoZWlnaHQ9IjMxLjkwNCIgdmlld0JveD0iMCAwIDMxLjQwNCAzMS45MDQiPg0KICA8Zz4NCiAgICA8cGF0aCBmaWxsPSJyZ2IoMCwxNjAsMjA5KSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMS40MDM4MDg2IDEuOTAzODA4NnYzMGwzMC0zMGgtMzB6Ii8+DQogICAgPHRleHQgZm9udC1zaXplPSIwIiBzdHlsZT0id2hpdGUtc3BhY2U6cHJlIiB0cmFuc2Zvcm09InJvdGF0ZSgtNDUuMDAwMDE2IDExLjY2NzI2MTg5IDExLjY2NzI2MTg5KSI+DQogICAgICA8dHNwYW4geD0iLjY2NyIgeT0iMTUuMTY3IiBmaWxsPSJyZ2IoMjU1LDI1NSwyNTUpIiBmb250LWZhbWlseT0iQXJpYWwiIGZvbnQtc2l6ZT0iMTAiIGZvbnQtd2VpZ2h0PSI0MDAiIHN0eWxlPSJ3aGl0ZS1zcGFjZTpwcmU7dGV4dC10cmFuc2Zvcm06bm9uZSI+TENNPC90c3Bhbj4NCiAgICA8L3RleHQ+DQogIDwvZz4NCjwvc3ZnPg0K");
  content: " ";
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  background-size: 30px 30px;
}

.new_indicator {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyNSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjg5MzEgMEgyLjAyNTE2QzAuODgwNTAzIDAgMCAwLjg4MDUwMyAwIDIuMDI1MTZWMTEuOTc0OEMwIDEzLjExOTUgMC44ODA1MDMgMTQgMi4wMjUxNiAxNEgyMi44OTMxQzI0LjAzNzcgMTQgMjQuOTE4MiAxMy4xMTk1IDI0LjkxODIgMTEuOTc0OFYyLjAyNTE2QzI0LjkxODIgMC44ODA1MDMgMjQuMDM3NyAwIDIyLjg5MzEgMFYwWk03LjIyMDEzIDEwLjMwMTlINS44MTEzMkwzLjA4MTc2IDQuOTMwODJWMTAuMzAxOUgyLjIwMTI2VjMuODc0MjFIMy40MzM5Nkw2LjI1MTU3IDkuNDIxMzhINi4zMzk2MlYzLjg3NDIxSDcuMjIwMTNWMTAuMzAxOVYxMC4zMDE5Wk05LjQyMTM4IDYuNjAzNzdIMTIuODU1M1Y3LjQ4NDI4SDkuNDIxMzhWNy42NjAzOEM5LjQyMTM4IDguODA1MDMgOS43NzM1OCA5LjMzMzMzIDEwLjQ3OCA5LjMzMzMzSDEyLjk0MzRWMTAuMjEzOEgxMC40NzhDOS45NDk2OCAxMC4yMTM4IDkuNTA5NDMgMTAuMDM3NyA5LjE1NzIzIDkuNTk3NDhDOC44MDUwMyA5LjE1NzIzIDguNjI4OTMgOC40NTI4MyA4LjYyODkzIDcuNjYwMzhWNi4xNjM1MkM4LjYyODkzIDQuNjY2NjcgOS41OTc0OCA0LjA1MDMxIDEwLjU2NiAzLjg3NDIxSDEyLjk0MzRWNC43NTQ3MkgxMC42NTQxQzEwLjMwMTkgNC44NDI3NyA5LjUwOTQzIDUuMTA2OTIgOS41MDk0MyA2LjE2MzUyQzkuNDIxMzggNi4zMzk2MiA5LjQyMTM4IDYuNDI3NjcgOS40MjEzOCA2LjYwMzc3Wk0yMC44Njc5IDEwLjMwMTlIMTkuNjM1MkwxOC40MDI1IDUuMTk0OTdMMTYuOTkzNyAxMC4zMDE5SDE1LjY3M0wxMy44MjM5IDMuODc0MjFIMTQuNzkyNUwxNi4zNzc0IDkuMTU3MjNIMTYuNDY1NEwxNy44NzQyIDMuODc0MjFIMTguOTMwOEwyMC4zMzk2IDkuNDIxMzhMMjIuMDEyNiAzLjg3NDIxSDIyLjg5MzFMMjAuODY3OSAxMC4zMDE5WiIgZmlsbD0iI0VENkIzMyIvPgo8L3N2Zz4K") !important;
  background-repeat: no-repeat !important;
  padding-left: 30px !important;
  background-position-y: center;
  // margin-top: -3px !important;
}

.ag-cell-label-container {
  height: 100%;
}

//React-checkbox-tree
.rct-text label {
  display: flex;
  align-items: center;
}

.react-checkbox-tree {
  max-height: 260px;
  overflow: auto;
}

.ag-header-group-cell-label {
  justify-content: center;
}
.ag-header-group-cell-no-group {
  background-color: #99ddff;
  pointer-events: none;
}

.green {
  color: #44cf50;
}

.red {
  color: #ff5f58;
}

.cell-span {
  background-color: #fff;
  // font-weight: bold;
  border-left: 1px solid #e5e5e5 !important;
  border-right: 1px solid #e5e5e5 !important;
  border-bottom: 1px solid #e5e5e5 !important;
}

.cell-noSpan {
  border-left: 1px solid #e5e5e5 !important;
  border-right: 1px solid #e5e5e5 !important;
  border-bottom: 1px solid #e5e5e5 !important;
}
.cell-rightBorder {
  border-right: 1px solid #e5e5e5 !important;
  padding: 0 10px !important;
  text-align: center;
  &:focus {
    border-right: 1px solid #3f51b5 !important;
  }
}
.cell-bottomBorder {
  border-bottom: 1px solid #ddd !important;
  line-height: 50px !important;
}

.cell-noBottomBorder {
  border-bottom: none !important;
}

.cell-noTopBorder {
  border-top: none !important;
}

.left-align {
  display: flex;
  justify-content: left;
}

.cont-span {
  color: white;
}

.ag-theme-material .ag-filter-toolpanel-group-item {
  padding-left: 24px;
}

.flatpickr-input {
  min-width: 200px;
  text-align: center;
}

.ag-header-group-text {
  font-weight: bold;
}

.ag-multiple-line-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-multiple-line-cell .ag-react-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2em;
}

.ag-react-container-full-width .ag-react-container {
  width: 100%;
}

.ag-react-container-full-height .ag-react-container {
  height: 100%;
}

.ag-filter-toolpanel {
  &-group-title {
    &:after {
      display: none;
    }
  }
}

.ag-tool-panel-wrapper {
  min-width: 300px;
}

.ag-tooltip {
  min-width: unset;
  max-width: 20vw;
}

.ag-row-group.ag-full-width-row {
  background-color: #f8f9fb !important;
  & .ag-group-value,
  & .ag-group-child-count {
    color: #333333 !important;
    font-weight: 500 !important;
  }
}

.slick-arrow.slick-prev:before,
.slick-arrow.slick-next:before {
  color: #535759;
}

.slick-arrow.slick-prev:before {
  content: url("data:image/svg+xml,%3Csvg width='13' height='23' viewBox='0 0 13 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 21.5977L11.5871 23L0 11.5001L11.5871 0L13 1.40225L2.82569 11.5001L13 21.5977Z' fill='%23535759'/%3E%3C/svg%3E%0A");
}
.slick-arrow.slick-next:before {
  content: url("data:image/svg+xml,%3Csvg width='13' height='23' viewBox='0 0 13 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 1.40227L1.41296 0L13 11.4999L1.41296 23L0 21.5977L10.1743 11.4999L0 1.40227Z' fill='%23535759'/%3E%3C/svg%3E%0A");
}

.benchmarking-darkRed,
.benchmarking-Q1 {
  background-color: darkred;
}

.benchmarking-lightRed,
.benchmarking-Q2 {
  background-color: #e2231a;
}

.benchmarking-yellow,
.benchmarking-Q3 {
  background-color: #fc0;
}

.benchmarking-green,
.benchmarking-Q4 {
  background-color: #6cc04a;
}

.ag-column-select-header-filter-wrapper input {
  padding-bottom: 0 !important;
  height: 30px !important;
}

.ag-filter-toolpanel-search {
  // padding-top: 8px;

  input {
    // padding-top: 8px;
    padding-bottom: 0 !important;
  }
}

// change scrollbar style
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #dedede;
  // for IE Edge
  scrollbar-3dlight-color: #dedede;
  scrollbar-highlight-color: #dedede;
  scrollbar-track-color: #dedede;
  scrollbar-arrow-color: #dedede;
  scrollbar-shadow-color: #dedede;
}

// for scroll rail
::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #dedede;
}

// for scrollbar style
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgb(190, 186, 186);
}

// for FF
html {
  scrollbar-width: thin;
  scrollbar-color: #dedede;
}

//ORA
.SignificantRisk,
.RED {
  color: #d4371c;
}

.ModerateRisk,
.ORANGE {
  color: #f26b1d;
}

.MinimalRisk,
.YELLOW {
  color: #ffc14f;
}

.NoRisk,
.GREEN {
  color: #00ab50;
}

font[size="1"] {
  font-size: 10px;
}

font[size="2"] {
  font-size: 12px;
}

font[size="3"] {
  font-size: 14px;
}

font[size="4"] {
  font-size: 16px;
}

font[size="5"] {
  font-size: 18px;
}

font[size="6"] {
  font-size: 20px;
}

font[size="7"] {
  font-size: 22px;
}

.w-e-text {
  padding: 0 16px !important;
  & * {
    font-size: unset;
    font-family: unset;
    margin: 0 !important;
  }
}

.ag-column-group-icons {
  margin-right: 16px !important;
}

.ag-tabs,
.ag-menu {
  max-width: 270px;
}

.dashboard-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #121212;

  & > span {
    color: #666666;
    font-size: 12px;
    line-height: 12px;
    margin-left: 16px;
  }
}

.tox-fullscreen {
  top: 64px !important;
}

.ep-revamp .tox-fullscreen {
  top: 192px !important;
}

.mention-avatar {
  width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
  object-fit: cover;
  margin-right: 12px !important;
}

.mention-role {
  color: #898989 !important;
  font-size: 12px !important;
}

.mention-name {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 12px !important;
  & span {
    font-size: 12px !important;
    line-height: 1.2 !important;
  }
}

.mce-mention {
  color: #0065ff !important;
  text-decoration: underline !important;
  font-weight: 500 !important;
}

.tox-tinymce-aux {
  z-index: 1301 !important;
  font-family: "CiscoSans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  line-height: 1.2 !important;
}

.listbox {
  & li[aria-selected="true"] {
    background-color: #edfbfe !important;
  }
  & li[aria-selected="false"]:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.listbox-option {
  padding: 0 !important;
  margin: 0 6px 6px 0;
  border-radius: 3px;
  background-color: transparent !important;
}

.dropdown-multiple-option {
  width: 100%;
}

.block-svg,
.rct-text {
  & svg.MuiSvgIcon-root {
    pointer-events: none;
  }
}

.ag-full-width-row.ag-row-level-1 {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 10px;
  background-color: #fafafa !important;
}

.hover-show {
  visibility: hidden;
}

.hover-show-container:hover {
  .hover-show {
    visibility: visible;
  }
}

.transform-txt {
  text-transform: capitalize;
}

.ag-tool-panel-wrapper {
  min-width: 542px;
}

// for hide ep revamp nav scrollbar
.nav-part::-webkit-scrollbar {
  display: none;
}

.nav-part {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}
